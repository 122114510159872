import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080 444">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="">
          <path
            fill="#FFFFFF"
            opacity="1.000000"
            stroke="none"
            d="
M750.000000,445.000000 
	C500.000000,445.000000 250.500000,445.000000 1.000000,445.000000 
	C1.000000,297.000000 1.000000,149.000000 1.000000,1.000000 
	C361.000000,1.000000 721.000000,1.000000 1081.000000,1.000000 
	C1081.000000,149.000000 1081.000000,297.000000 1081.000000,445.000000 
	C970.833313,445.000000 860.666687,445.000000 750.000000,445.000000 
M546.066956,220.087402 
	C546.066956,220.087402 546.076660,220.207291 546.710327,220.122467 
	C547.185120,219.763306 547.659851,219.404129 548.712158,219.300369 
	C549.509277,220.536377 550.306396,221.772400 551.049377,223.501999 
	C551.184631,223.849258 551.437988,224.038513 551.959167,224.649628 
	C552.291870,225.111633 552.624573,225.573639 553.052795,226.677460 
	C553.678650,227.528610 554.304443,228.379761 554.961426,229.631058 
	C554.961426,229.631058 555.174194,229.971344 555.145386,230.494263 
	C555.418518,230.688629 555.691650,230.883011 555.965820,231.000504 
	C555.965820,231.000504 555.917358,231.060196 556.018127,231.734146 
	C556.993958,233.218689 557.969788,234.703232 558.989990,236.606384 
	C558.989990,236.606384 559.190063,236.976746 559.153381,237.487427 
	C559.421326,237.677338 559.689331,237.867233 559.984619,237.993881 
	C559.984619,237.993881 559.941467,238.049774 560.029907,238.680496 
	C560.705444,239.734558 561.380981,240.788620 561.989441,241.937027 
	C561.989441,241.937027 562.083618,242.004395 562.082458,242.550156 
	C562.372864,242.754745 562.663208,242.959320 563.015198,243.724442 
	C563.322388,244.155991 563.629639,244.587540 564.057800,245.642838 
	C564.336609,245.947952 564.615479,246.253067 564.830261,246.804733 
	C564.830261,246.804733 565.034729,246.956726 565.081421,247.505554 
	C565.362549,247.717056 565.643677,247.928558 565.932068,248.050735 
	C565.932068,248.050735 565.844421,248.069519 565.910217,248.718475 
	C566.421265,249.641464 566.932373,250.564453 567.698486,251.948013 
	C568.356750,250.681290 568.778259,249.870087 569.783691,248.721130 
	C584.730225,222.795593 599.676758,196.870071 615.025574,170.390808 
	C613.782532,169.932343 612.543457,169.087997 611.295837,169.075119 
	C598.886963,168.947006 586.476624,168.956238 574.000183,168.004333 
	C573.995483,163.505035 574.111084,159.001434 573.949524,154.507767 
	C573.810608,150.644943 573.355225,146.793488 573.008911,143.001892 
	C573.008911,143.001892 573.066284,142.958542 572.825134,142.376495 
	C572.229736,142.300522 571.634338,142.224533 570.433777,141.897644 
	C569.665039,141.583344 568.896240,141.269043 567.975098,140.257523 
	C563.244629,137.404602 558.514160,134.551666 553.292419,131.156799 
	C550.592529,129.749710 547.892700,128.342621 544.848999,126.318443 
	C540.307251,123.852509 535.765503,121.386574 530.865479,118.300461 
	C528.014771,116.676437 525.230103,114.914848 522.279236,113.499634 
	C521.180115,112.972511 519.395508,112.723213 518.433228,113.260338 
	C514.162598,115.644157 510.076935,118.359413 505.250183,121.111092 
	C502.499512,122.758789 499.748840,124.406487 496.449799,126.057587 
	C496.230072,126.331093 496.010376,126.604607 495.922668,126.899078 
	C495.922668,126.899078 495.903961,126.766754 495.295929,126.886406 
	C494.830719,127.235023 494.365509,127.583641 493.974731,127.968895 
	C493.974731,127.968895 493.962219,127.886894 493.354431,127.975815 
	C492.883942,128.304871 492.413452,128.633926 491.991608,129.004257 
	C491.991608,129.004257 491.964722,128.946625 491.279724,129.110367 
	C484.537262,133.044907 477.794830,136.979446 470.485291,141.030609 
	C470.245575,141.311905 470.005829,141.593201 469.886200,141.877457 
	C469.886200,141.877457 469.838806,141.767075 469.035858,141.914841 
	C468.353943,143.066498 467.084900,144.214523 467.077728,145.370392 
	C466.959534,164.429153 466.976196,183.489014 467.088348,202.547913 
	C467.093048,203.350479 468.317261,204.145889 469.650696,205.177277 
	C472.745087,207.149429 475.839508,209.121567 478.999725,211.017883 
	C478.999725,211.017883 478.911621,211.066040 479.155396,211.642899 
	C479.738251,211.844925 480.321106,212.046951 480.916779,212.127731 
	C480.916779,212.127731 480.799866,212.162384 481.098389,212.726318 
	C482.049744,213.175232 483.001068,213.624146 483.993378,214.006165 
	C483.993378,214.006165 483.923431,214.041641 484.099121,214.513214 
	C484.393860,214.610458 484.688599,214.707687 485.396881,215.009323 
	C485.396881,215.009323 485.856171,215.052536 486.062195,215.550232 
	C486.377808,215.666794 486.693420,215.783356 487.279663,216.135788 
	C487.279663,216.135788 487.637970,216.112839 488.025726,216.694168 
	C489.661194,217.505447 491.296692,218.316727 492.954681,219.048569 
	C492.954681,219.048569 492.872223,219.052521 493.055573,219.563171 
	C493.374329,219.682404 493.693085,219.801651 494.524017,220.375198 
	C496.923615,221.663940 499.323181,222.952667 501.737183,224.211166 
	C501.737183,224.211166 501.767273,224.217163 502.090546,224.765182 
	C503.043488,225.198074 503.996399,225.630981 505.003418,226.005829 
	C505.003418,226.005829 504.942078,226.054092 505.203003,226.614670 
	C505.787720,226.786835 506.372406,226.959015 506.961761,227.047943 
	C506.961761,227.047943 506.879669,227.062531 507.054749,227.574738 
	C507.372406,227.695053 507.690063,227.815369 508.521240,228.398361 
	C511.747498,230.272217 514.875000,232.361511 518.250854,233.908981 
	C519.518921,234.490219 521.363647,233.813354 523.597839,233.548080 
	C528.785034,230.433624 533.972290,227.319168 539.117859,224.169678 
	C539.117859,224.169678 539.136230,224.208374 539.800903,224.115295 
	C540.880493,223.424026 541.960083,222.732773 543.002441,221.998657 
	C543.002441,221.998657 543.031189,222.046326 543.554749,221.923065 
	C543.746338,221.629929 543.937927,221.336792 544.577087,220.991806 
	C544.577087,220.991806 545.000244,220.837082 545.535461,220.910629 
	C545.751892,220.644485 545.968323,220.378326 546.066956,220.087402 
M701.010010,304.299683 
	C701.011169,302.137787 701.045532,299.975311 701.007996,297.814087 
	C700.773987,284.341919 692.205139,273.163391 678.983337,271.309174 
	C668.241943,269.802765 657.240967,270.147369 646.231689,269.665680 
	C646.231689,291.298218 646.231689,311.998871 646.231689,333.003937 
	C654.925720,333.003937 663.377502,333.083862 671.827332,332.986725 
	C687.784546,332.803284 699.770630,321.351166 701.010010,304.299683 
M552.464722,334.009003 
	C558.706177,334.454468 563.997681,332.701202 568.022949,326.008575 
	C568.022949,329.147614 568.022949,330.905243 568.022949,332.687042 
	C572.357056,332.687042 576.417236,332.687042 580.451782,332.687042 
	C580.451782,310.305450 580.451782,288.248932 580.451782,266.220093 
	C576.185730,266.220093 572.250061,266.220093 567.932373,266.220093 
	C567.932373,274.141357 567.932373,281.842621 567.932373,289.985962 
	C562.320923,283.471497 554.353821,281.888397 545.815186,284.654053 
	C534.068604,288.458740 528.509888,301.690125 530.555542,313.650848 
	C533.003723,327.965057 539.567444,334.008209 552.464722,334.009003 
M418.142883,298.990112 
	C417.758331,303.580292 417.090637,308.167908 417.049713,312.761169 
	C416.970520,321.653076 422.210693,330.293152 429.783020,332.676575 
	C433.909454,333.975403 438.710419,334.476807 442.930023,333.743408 
	C446.892273,333.054718 450.489868,330.267975 454.144165,328.455872 
	C454.144165,329.135925 454.144165,330.874237 454.144165,332.763672 
	C458.553589,332.763672 462.626740,332.763672 466.786163,332.763672 
	C466.786163,310.462372 466.786163,288.404266 466.786163,266.308716 
	C462.509155,266.308716 458.459930,266.308716 453.833740,266.308716 
	C453.833740,274.213348 453.833740,281.817291 453.833740,290.252533 
	C450.156494,284.767761 445.092926,282.664825 438.652100,283.324158 
	C428.977661,284.314453 421.924316,288.836029 418.142883,298.990112 
M278.502594,314.006683 
	C280.333191,314.006683 282.216431,314.285217 283.983826,313.950958 
	C288.173035,313.158691 289.836700,315.169403 290.808960,318.921448 
	C292.032745,323.644043 293.733521,328.243011 295.186554,332.764954 
	C299.857330,332.764954 304.226349,332.764954 308.785583,332.764954 
	C301.710541,312.610168 294.736908,292.731659 287.742889,272.860352 
	C287.424652,271.956085 286.923248,271.116272 286.429626,270.084442 
	C282.480896,270.084442 278.487610,270.219910 274.509155,270.035339 
	C271.989746,269.918427 270.791809,270.699036 269.921204,273.217682 
	C265.756897,285.265076 261.332306,297.222504 256.998322,309.211243 
	C254.184708,316.994263 251.367828,324.776154 248.390625,333.005768 
	C250.110062,333.005768 251.097198,333.005798 252.084351,333.005798 
	C255.237747,333.005798 258.391144,333.005768 261.665100,333.005768 
	C262.814819,329.642792 263.964050,326.436432 265.008575,323.196289 
	C267.969177,314.012573 267.952301,314.007141 278.502594,314.006683 
M820.323120,283.996277 
	C814.945557,284.334198 809.363037,283.878510 804.239746,285.201263 
	C797.060425,287.054932 792.987732,293.619080 793.570435,300.362854 
	C798.785217,299.705139 805.390259,302.627563 807.452271,294.376740 
	C807.596985,293.797821 808.761841,293.107971 809.461487,293.097778 
	C812.288635,293.056488 816.112549,292.174408 817.711304,293.635284 
	C819.995728,295.722504 820.652405,299.591125 822.295227,303.367920 
	C818.037476,303.618774 815.405640,303.696045 812.790894,303.949158 
	C810.007507,304.218536 807.179260,304.404846 804.477295,305.064331 
	C796.411743,307.032959 791.099670,313.058685 790.993408,320.030487 
	C790.900696,326.115936 794.630493,331.937164 800.923035,333.254761 
	C808.192078,334.776733 815.943298,335.963196 821.142517,327.285400 
	C821.650452,329.625397 821.991272,331.195740 822.338501,332.795593 
	C826.149292,332.795593 829.916565,332.795593 833.057373,332.795593 
	C833.057373,321.816010 833.246155,311.052185 833.002136,300.298187 
	C832.776611,290.358856 830.455933,287.471954 820.323120,283.996277 
M631.128845,296.489380 
	C629.887024,288.072906 626.117676,284.855774 614.700378,283.532990 
	C603.749390,282.264191 591.722229,287.975677 591.540222,300.138763 
	C592.149170,300.198364 592.773682,300.309082 593.398804,300.312408 
	C596.709229,300.330048 600.019897,300.320679 603.192200,300.320679 
	C605.036560,293.922424 608.608643,291.177094 613.362976,292.143097 
	C618.377014,293.161865 619.724854,296.094269 618.762146,302.035980 
	C610.842346,304.138184 603.224365,305.450104 596.198669,308.248810 
	C590.527710,310.507843 589.318970,316.199097 589.791809,322.091064 
	C590.242065,327.701996 593.232971,331.666290 598.406372,332.946167 
	C606.234985,334.882965 614.346741,336.157013 619.999939,326.530884 
	C619.999939,329.334747 619.999939,330.976166 619.999939,332.787292 
	C624.280518,332.787292 628.382141,332.787292 632.005737,332.787292 
	C632.005737,329.952820 632.059570,327.343842 631.995911,324.737732 
	C631.772766,315.604797 631.509155,306.472839 631.128845,296.489380 
M711.378357,292.080414 
	C710.435852,294.625366 709.493408,297.170288 708.160095,300.770569 
	C714.345398,299.305481 721.120117,303.144653 723.214294,294.611603 
	C723.364563,293.999237 724.469971,293.218414 725.143372,293.206635 
	C728.119995,293.154633 732.068787,292.227386 733.838928,293.746155 
	C736.126526,295.708832 736.674683,299.698700 738.057739,303.022095 
	C731.182922,303.803955 725.686768,303.985626 720.400269,305.133392 
	C712.306030,306.890778 707.110718,313.076721 706.999207,320.058075 
	C706.899475,326.301117 711.562561,332.289246 717.729858,333.837891 
	C725.880981,335.884735 733.345154,333.379730 736.872498,327.187622 
	C737.440430,329.252319 737.946106,331.091003 738.386414,332.691772 
	C742.394348,332.691772 746.139404,332.691772 749.004639,332.691772 
	C749.004639,320.430176 749.350159,308.475037 748.773804,296.564545 
	C748.620178,293.390015 746.301392,289.627197 743.837097,287.389496 
	C736.899902,281.089996 717.338074,281.759247 711.378357,292.080414 
M365.875610,293.439514 
	C359.393829,303.935120 359.921082,317.113556 367.187836,326.239136 
	C372.825195,333.318542 385.677094,336.320831 395.967590,332.962311 
	C408.359222,328.918060 414.725281,314.546173 410.539948,300.209717 
	C408.049225,291.678040 402.389648,286.023621 393.932373,284.232880 
	C383.376129,281.997742 373.420959,283.509247 365.875610,293.439514 
M336.061890,292.115234 
	C341.241150,293.295929 343.457489,297.099609 344.379761,301.844452 
	C348.618347,301.844452 352.588623,301.844452 356.569885,301.844452 
	C355.464233,286.836853 340.121155,280.612396 326.829437,284.603699 
	C312.467773,288.916382 307.830200,304.301270 311.729797,318.372009 
	C315.687439,332.652039 327.636932,336.348267 341.195343,333.157715 
	C350.646332,330.933746 355.874847,324.289368 355.898071,316.166565 
	C352.078796,316.166565 348.259766,316.166565 344.424805,316.166565 
	C344.161926,316.844116 343.921600,317.448730 343.692108,318.057434 
	C341.950256,322.677002 339.014160,325.562408 333.680298,325.309082 
	C328.313904,325.054260 325.671692,321.712585 324.591705,317.046448 
	C323.816284,313.695892 323.245575,310.133636 323.508850,306.737579 
	C324.319672,296.278992 327.310486,292.804657 336.061890,292.115234 
M526.666626,265.143158 
	C525.521973,264.946228 524.377075,264.577545 523.232727,264.579254 
	C512.946838,264.594574 506.402130,266.241638 504.214355,276.705872 
	C503.688141,279.222748 504.136169,281.943298 504.136169,284.698883 
	C502.148438,284.948761 500.610291,285.142120 499.146729,285.326111 
	C499.146729,287.961823 499.146729,290.252197 499.146729,292.789276 
	C500.788025,292.955139 502.325928,293.110565 504.215515,293.301514 
	C504.215515,306.719055 504.215515,319.786438 504.215515,332.641113 
	C508.685181,332.641113 512.743042,332.641113 517.204468,332.641113 
	C517.204468,319.215027 517.204468,306.151794 517.204468,292.761841 
	C520.555115,292.761841 523.490784,292.761841 526.411377,292.761841 
	C526.411377,290.014099 526.411377,287.617645 526.411377,284.904907 
	C523.146240,284.904907 520.201294,284.904907 516.599426,284.904907 
	C517.030945,281.654724 516.433228,277.854950 518.047729,276.150299 
	C519.957642,274.133698 523.776794,273.925293 527.109924,272.819794 
	C527.109924,270.883881 527.109924,268.403870 526.666626,265.143158 
M776.009705,295.694366 
	C776.949768,294.799683 777.784607,293.324860 778.851807,293.131287 
	C781.062866,292.730316 783.397400,293.010376 785.803467,293.010376 
	C785.803467,290.163239 785.803467,287.755676 785.803467,284.961487 
	C782.529114,284.961487 779.440735,284.961487 775.981079,284.961487 
	C775.981079,280.838928 775.981079,277.086212 775.981079,273.219269 
	C771.681213,273.219269 767.778564,273.219269 763.436035,273.219269 
	C763.436035,277.177917 763.436035,280.924896 763.436035,284.788147 
	C760.767700,284.953552 758.519836,285.092896 756.480774,285.219330 
	C756.480774,288.073395 756.480774,290.468323 756.480774,292.857147 
	C758.959045,292.990082 761.079651,293.103821 763.883362,293.254181 
	C763.883362,303.779053 763.705872,314.070251 763.953918,324.351196 
	C764.089600,329.975403 766.741394,333.007019 772.224548,333.760651 
	C776.258118,334.315033 780.429199,333.869385 784.777832,333.869385 
	C784.777832,330.649567 784.777832,327.939178 784.777832,325.244904 
	C776.363159,324.859344 776.014587,324.487793 776.009766,316.145874 
	C776.005981,309.652313 776.009216,303.158783 776.009705,295.694366 
M488.360046,284.920502 
	C485.251221,284.920502 482.142426,284.920502 478.919189,284.920502 
	C478.919189,301.128113 478.919189,316.830627 478.919189,332.749207 
	C482.958771,332.749207 486.834869,332.749207 490.999969,332.749207 
	C490.999969,317.421295 491.024994,302.342438 490.928589,287.264313 
	C490.923584,286.481628 489.882751,285.705566 488.360046,284.920502 
M482.928467,266.976349 
	C481.282654,268.947601 478.751312,270.729492 478.276306,272.951050 
	C477.908356,274.671722 479.686890,278.119507 481.272736,278.692688 
	C483.653473,279.553223 487.219238,279.397583 489.294281,278.098175 
	C490.846527,277.126129 491.670776,273.548035 491.350281,271.343994 
	C490.770233,267.354980 487.390686,266.537659 482.928467,266.976349 
z"
          />
          <path
            fill="#020202"
            opacity="1.000000"
            stroke="none"
            d="
M701.007324,304.765198 
	C699.770630,321.351166 687.784546,332.803284 671.827332,332.986725 
	C663.377502,333.083862 654.925720,333.003937 646.231689,333.003937 
	C646.231689,311.998871 646.231689,291.298218 646.231689,269.665680 
	C657.240967,270.147369 668.241943,269.802765 678.983337,271.309174 
	C692.205139,273.163391 700.773987,284.341919 701.007996,297.814087 
	C701.045532,299.975311 701.011169,302.137787 701.007324,304.765198 
M673.498901,280.845154 
	C668.461670,280.461029 663.424438,280.076874 658.289001,279.685272 
	C658.289001,294.579437 658.289001,308.636566 658.289001,322.998566 
	C662.509277,322.998566 666.478943,323.098114 670.441833,322.975891 
	C676.839966,322.778503 682.563416,320.324188 684.751465,314.259521 
	C686.756165,308.702942 688.074890,302.378052 687.583008,296.570648 
	C686.937195,288.945496 683.205322,282.378082 673.498901,280.845154 
z"
          />
          <path
            fill="#060606"
            opacity="1.000000"
            stroke="none"
            d="
M552.006714,334.008972 
	C539.567444,334.008209 533.003723,327.965057 530.555542,313.650848 
	C528.509888,301.690125 534.068604,288.458740 545.815186,284.654053 
	C554.353821,281.888397 562.320923,283.471497 567.932373,289.985962 
	C567.932373,281.842621 567.932373,274.141357 567.932373,266.220093 
	C572.250061,266.220093 576.185730,266.220093 580.451782,266.220093 
	C580.451782,288.248932 580.451782,310.305450 580.451782,332.687042 
	C576.417236,332.687042 572.357056,332.687042 568.022949,332.687042 
	C568.022949,330.905243 568.022949,329.147614 568.022949,326.008575 
	C563.997681,332.701202 558.706177,334.454468 552.006714,334.008972 
M543.082397,308.579285 
	C543.412231,311.038177 543.519897,313.552429 544.116089,315.944916 
	C545.542236,321.667633 549.948303,325.254913 555.302490,325.356873 
	C561.685547,325.478424 565.408630,322.958893 566.924866,316.235565 
	C567.889221,311.959381 568.164551,307.337982 567.712830,302.978760 
	C567.107056,297.133301 562.977844,293.029449 557.744629,292.186554 
	C552.637207,291.363922 548.417358,293.476196 546.019470,297.853333 
	C544.406921,300.796875 544.021973,304.412903 543.082397,308.579285 
z"
          />
          <path
            fill="#030303"
            opacity="1.000000"
            stroke="none"
            d="
M418.334656,298.633179 
	C421.924316,288.836029 428.977661,284.314453 438.652100,283.324158 
	C445.092926,282.664825 450.156494,284.767761 453.833740,290.252533 
	C453.833740,281.817291 453.833740,274.213348 453.833740,266.308716 
	C458.459930,266.308716 462.509155,266.308716 466.786163,266.308716 
	C466.786163,288.404266 466.786163,310.462372 466.786163,332.763672 
	C462.626740,332.763672 458.553589,332.763672 454.144165,332.763672 
	C454.144165,330.874237 454.144165,329.135925 454.144165,328.455872 
	C450.489868,330.267975 446.892273,333.054718 442.930023,333.743408 
	C438.710419,334.476807 433.909454,333.975403 429.783020,332.676575 
	C422.210693,330.293152 416.970520,321.653076 417.049713,312.761169 
	C417.090637,308.167908 417.758331,303.580292 418.334656,298.633179 
M453.997467,303.925079 
	C453.109283,296.914856 449.084747,292.563995 443.006439,292.042816 
	C437.552582,291.575195 432.227203,295.029846 431.014893,301.476624 
	C430.046722,306.625183 430.081909,312.342590 431.286957,317.424500 
	C432.830048,323.932159 437.789429,326.109833 444.806641,325.035278 
	C449.830872,324.265900 453.685913,319.625366 453.973694,313.786499 
	C454.120514,310.807709 454.006622,307.816101 453.997467,303.925079 
z"
          />
          <path
            fill="#030303"
            opacity="1.000000"
            stroke="none"
            d="
M278.016968,314.006683 
	C267.952301,314.007141 267.969177,314.012573 265.008575,323.196289 
	C263.964050,326.436432 262.814819,329.642792 261.665100,333.005768 
	C258.391144,333.005768 255.237747,333.005798 252.084351,333.005798 
	C251.097198,333.005798 250.110062,333.005768 248.390625,333.005768 
	C251.367828,324.776154 254.184708,316.994263 256.998322,309.211243 
	C261.332306,297.222504 265.756897,285.265076 269.921204,273.217682 
	C270.791809,270.699036 271.989746,269.918427 274.509155,270.035339 
	C278.487610,270.219910 282.480896,270.084442 286.429626,270.084442 
	C286.923248,271.116272 287.424652,271.956085 287.742889,272.860352 
	C294.736908,292.731659 301.710541,312.610168 308.785583,332.764954 
	C304.226349,332.764954 299.857330,332.764954 295.186554,332.764954 
	C293.733521,328.243011 292.032745,323.644043 290.808960,318.921448 
	C289.836700,315.169403 288.173035,313.158691 283.983826,313.950958 
	C282.216431,314.285217 280.333191,314.006683 278.016968,314.006683 
M277.904541,283.786316 
	C275.707275,290.440063 273.510010,297.093811 271.299622,303.787231 
	C276.436951,303.787231 280.971497,303.787231 285.685028,303.787231 
	C284.348938,299.286377 283.178528,295.046814 281.820618,290.868195 
	C280.825409,287.805817 279.584015,284.823395 278.452332,281.805359 
	C278.320831,282.063721 278.170715,282.314728 278.065216,282.583313 
	C278.006927,282.731750 278.023438,282.909607 277.904541,283.786316 
z"
          />
          <path
            fill="#3D33B0"
            opacity="1.000000"
            stroke="none"
            d="
M487.637970,216.112823 
	C487.637970,216.112839 487.279663,216.135788 487.143616,215.791336 
	C487.007538,215.446884 487.001801,214.992462 487.140839,214.681549 
	C489.056610,211.819565 491.048920,209.389297 492.561371,206.690125 
	C495.054016,202.241730 497.188873,197.594543 499.558899,193.075394 
	C505.159180,182.396805 511.062988,171.868713 516.368042,161.046432 
	C521.763550,150.039520 526.575928,138.747192 531.671631,127.592621 
	C533.276672,124.079124 535.837524,123.666748 539.198914,125.177116 
	C541.080383,126.022514 543.187439,126.365929 545.192810,126.935516 
	C547.892700,128.342621 550.592529,129.749710 553.091553,131.501221 
	C547.827637,131.561523 545.756897,135.302902 543.956360,138.923630 
	C539.020935,148.848206 534.451111,158.954422 529.528320,168.885544 
	C521.098938,185.890579 512.510986,202.817078 503.853577,220.047409 
	C503.902283,220.054825 503.125000,219.936401 502.212952,219.797440 
	C502.044769,221.322205 501.883759,222.781799 501.722778,224.241394 
	C499.323181,222.952667 496.923615,221.663940 494.138336,219.933960 
	C493.459167,219.345993 493.165710,219.199265 492.872223,219.052521 
	C492.872223,219.052521 492.954681,219.048569 492.912781,218.714386 
	C491.126587,217.624420 489.382294,216.868622 487.637970,216.112823 
z"
          />
          <path
            fill="#3A39BD"
            opacity="1.000000"
            stroke="none"
            d="
M501.729980,224.226288 
	C501.883759,222.781799 502.044769,221.322205 502.212952,219.797440 
	C503.125000,219.936401 503.902283,220.054825 503.853577,220.047409 
	C512.510986,202.817078 521.098938,185.890579 529.528320,168.885544 
	C534.451111,158.954422 539.020935,148.848206 543.956360,138.923630 
	C545.756897,135.302902 547.827637,131.561523 553.337219,131.772186 
	C558.514160,134.551666 563.244629,137.404602 567.660583,140.583405 
	C564.357544,140.909302 561.369019,140.909302 559.695129,140.909302 
	C554.278503,153.110809 549.403198,164.093094 544.087402,176.067551 
	C551.553162,176.426605 557.282104,176.702133 563.477417,176.982086 
	C569.284180,177.335388 574.624756,177.684265 579.508911,178.129456 
	C564.873657,178.225769 550.694763,178.225769 536.220825,178.225769 
	C537.717468,181.607254 538.895813,184.269653 539.738708,186.908325 
	C538.330017,186.362915 537.256775,185.841217 535.832886,185.149094 
	C528.510681,200.120972 521.382202,214.696625 513.945190,229.903214 
	C517.136902,231.253220 520.041199,232.481644 522.945496,233.710068 
	C521.363647,233.813354 519.518921,234.490219 518.250854,233.908981 
	C514.875000,232.361511 511.747498,230.272217 508.137146,227.952545 
	C507.517609,227.249222 507.226471,227.101151 506.879669,227.062531 
	C506.879669,227.062531 506.961761,227.047943 506.815765,226.812576 
	C506.093872,226.402832 505.517975,226.228470 504.942078,226.054092 
	C504.942078,226.054092 505.003418,226.005829 504.938599,225.668716 
	C503.838257,224.960129 502.802765,224.588638 501.767273,224.217163 
	C501.767273,224.217163 501.737183,224.211166 501.729980,224.226288 
z"
          />
          <path
            fill="#402EA2"
            opacity="1.000000"
            stroke="none"
            d="
M545.020874,126.626984 
	C543.187439,126.365929 541.080383,126.022514 539.198914,125.177116 
	C535.837524,123.666748 533.276672,124.079124 531.671631,127.592621 
	C526.575928,138.747192 521.763550,150.039520 516.368042,161.046432 
	C511.062988,171.868713 505.159180,182.396805 499.558899,193.075394 
	C497.188873,197.594543 495.054016,202.241730 492.561371,206.690125 
	C491.048920,209.389297 489.056610,211.819565 486.854004,214.689423 
	C486.237488,215.022995 486.046814,215.037766 485.856171,215.052536 
	C485.856171,215.052536 485.396881,215.009323 485.061066,214.721237 
	C484.501099,214.214325 484.233826,214.083832 483.923431,214.041641 
	C483.923431,214.041641 483.993378,214.006165 483.933777,213.675049 
	C482.849396,212.950089 481.824615,212.556244 480.799866,212.162384 
	C480.799866,212.162384 480.916779,212.127731 480.792175,211.887665 
	C480.082245,211.453735 479.496918,211.259888 478.911621,211.066040 
	C478.911621,211.066040 478.999725,211.017883 478.987976,210.653961 
	C478.623505,208.044891 478.270813,205.799744 477.918091,203.554596 
	C478.236206,203.630615 478.554321,203.706619 478.872406,203.782639 
	C479.355286,201.326096 479.838165,198.869553 480.469177,195.659363 
	C481.174927,195.053970 483.296783,194.015762 484.204559,192.325500 
	C489.283203,182.869476 494.179413,173.310471 498.919708,163.679245 
	C502.844055,155.705933 506.544342,147.619003 510.184723,139.510941 
	C511.682739,136.174423 512.719971,132.633392 514.065979,129.224548 
	C514.389709,128.404587 514.984436,127.261765 515.666199,127.085258 
	C519.171875,126.177696 519.235474,123.596443 518.989197,120.835747 
	C518.668579,117.241013 520.399048,116.509148 523.529724,117.304970 
	C526.067322,117.950035 528.657104,118.389809 531.223755,118.920639 
	C535.765503,121.386574 540.307251,123.852509 545.020874,126.626984 
z"
          />
          <path
            fill="#030303"
            opacity="1.000000"
            stroke="none"
            d="
M820.736328,284.012024 
	C830.455933,287.471954 832.776611,290.358856 833.002136,300.298187 
	C833.246155,311.052185 833.057373,321.816010 833.057373,332.795593 
	C829.916565,332.795593 826.149292,332.795593 822.338501,332.795593 
	C821.991272,331.195740 821.650452,329.625397 821.142517,327.285400 
	C815.943298,335.963196 808.192078,334.776733 800.923035,333.254761 
	C794.630493,331.937164 790.900696,326.115936 790.993408,320.030487 
	C791.099670,313.058685 796.411743,307.032959 804.477295,305.064331 
	C807.179260,304.404846 810.007507,304.218536 812.790894,303.949158 
	C815.405640,303.696045 818.037476,303.618774 822.295227,303.367920 
	C820.652405,299.591125 819.995728,295.722504 817.711304,293.635284 
	C816.112549,292.174408 812.288635,293.056488 809.461487,293.097778 
	C808.761841,293.107971 807.596985,293.797821 807.452271,294.376740 
	C805.390259,302.627563 798.785217,299.705139 793.570435,300.362854 
	C792.987732,293.619080 797.060425,287.054932 804.239746,285.201263 
	C809.363037,283.878510 814.945557,284.334198 820.736328,284.012024 
M812.433350,311.684265 
	C806.496155,312.949127 803.317200,316.160980 804.038025,320.166443 
	C804.726257,323.990662 808.315430,325.890350 813.397766,325.120392 
	C819.163147,324.246918 821.582275,319.599457 820.521851,310.928284 
	C818.119202,311.067535 815.671753,311.209412 812.433350,311.684265 
z"
          />
          <path
            fill="#030303"
            opacity="1.000000"
            stroke="none"
            d="
M631.194824,296.914948 
	C631.509155,306.472839 631.772766,315.604797 631.995911,324.737732 
	C632.059570,327.343842 632.005737,329.952820 632.005737,332.787292 
	C628.382141,332.787292 624.280518,332.787292 619.999939,332.787292 
	C619.999939,330.976166 619.999939,329.334747 619.999939,326.530884 
	C614.346741,336.157013 606.234985,334.882965 598.406372,332.946167 
	C593.232971,331.666290 590.242065,327.701996 589.791809,322.091064 
	C589.318970,316.199097 590.527710,310.507843 596.198669,308.248810 
	C603.224365,305.450104 610.842346,304.138184 618.762146,302.035980 
	C619.724854,296.094269 618.377014,293.161865 613.362976,292.143097 
	C608.608643,291.177094 605.036560,293.922424 603.192200,300.320679 
	C600.019897,300.320679 596.709229,300.330048 593.398804,300.312408 
	C592.773682,300.309082 592.149170,300.198364 591.540222,300.138763 
	C591.722229,287.975677 603.749390,282.264191 614.700378,283.532990 
	C626.117676,284.855774 629.887024,288.072906 631.194824,296.914948 
M614.357117,324.359680 
	C619.085449,320.981110 620.021362,317.862244 618.169189,310.347778 
	C614.212219,311.255920 610.129639,311.839844 606.308289,313.172638 
	C602.736023,314.418549 601.810059,317.942078 603.137207,320.956848 
	C603.966003,322.839661 606.673035,324.182892 608.823181,325.092316 
	C610.178040,325.665344 612.060913,324.989777 614.357117,324.359680 
z"
          />
          <path
            fill="#030303"
            opacity="1.000000"
            stroke="none"
            d="
M711.652100,291.825165 
	C717.338074,281.759247 736.899902,281.089996 743.837097,287.389496 
	C746.301392,289.627197 748.620178,293.390015 748.773804,296.564545 
	C749.350159,308.475037 749.004639,320.430176 749.004639,332.691772 
	C746.139404,332.691772 742.394348,332.691772 738.386414,332.691772 
	C737.946106,331.091003 737.440430,329.252319 736.872498,327.187622 
	C733.345154,333.379730 725.880981,335.884735 717.729858,333.837891 
	C711.562561,332.289246 706.899475,326.301117 706.999207,320.058075 
	C707.110718,313.076721 712.306030,306.890778 720.400269,305.133392 
	C725.686768,303.985626 731.182922,303.803955 738.057739,303.022095 
	C736.674683,299.698700 736.126526,295.708832 733.838928,293.746155 
	C732.068787,292.227386 728.119995,293.154633 725.143372,293.206635 
	C724.469971,293.218414 723.364563,293.999237 723.214294,294.611603 
	C721.120117,303.144653 714.345398,299.305481 708.160095,300.770569 
	C709.493408,297.170288 710.435852,294.625366 711.652100,291.825165 
M734.773560,322.198151 
	C735.727783,318.602020 736.682068,315.005920 737.900940,310.412354 
	C733.103943,311.091431 729.961426,311.339539 726.913208,312.013794 
	C722.302002,313.033722 719.343750,316.732910 720.016052,320.210083 
	C720.750427,324.008148 724.378296,325.999756 729.445618,325.032990 
	C731.126648,324.712311 732.651672,323.573761 734.773560,322.198151 
z"
          />
          <path
            fill="#050505"
            opacity="1.000000"
            stroke="none"
            d="
M365.992432,293.091309 
	C373.420959,283.509247 383.376129,281.997742 393.932373,284.232880 
	C402.389648,286.023621 408.049225,291.678040 410.539948,300.209717 
	C414.725281,314.546173 408.359222,328.918060 395.967590,332.962311 
	C385.677094,336.320831 372.825195,333.318542 367.187836,326.239136 
	C359.921082,317.113556 359.393829,303.935120 365.992432,293.091309 
M390.678558,324.896851 
	C400.692261,320.865448 400.631073,302.073730 394.653107,295.628479 
	C390.436432,291.082184 383.205048,290.381226 379.398071,295.244476 
	C376.752991,298.623383 375.114960,303.311462 374.465668,307.630920 
	C372.749939,319.044586 379.302551,326.075409 390.678558,324.896851 
z"
          />
          <path
            fill="#050505"
            opacity="1.000000"
            stroke="none"
            d="
M335.664185,292.085602 
	C327.310486,292.804657 324.319672,296.278992 323.508850,306.737579 
	C323.245575,310.133636 323.816284,313.695892 324.591705,317.046448 
	C325.671692,321.712585 328.313904,325.054260 333.680298,325.309082 
	C339.014160,325.562408 341.950256,322.677002 343.692108,318.057434 
	C343.921600,317.448730 344.161926,316.844116 344.424805,316.166565 
	C348.259766,316.166565 352.078796,316.166565 355.898071,316.166565 
	C355.874847,324.289368 350.646332,330.933746 341.195343,333.157715 
	C327.636932,336.348267 315.687439,332.652039 311.729797,318.372009 
	C307.830200,304.301270 312.467773,288.916382 326.829437,284.603699 
	C340.121155,280.612396 355.464233,286.836853 356.569885,301.844452 
	C352.588623,301.844452 348.618347,301.844452 344.379761,301.844452 
	C343.457489,297.099609 341.241150,293.295929 335.664185,292.085602 
z"
          />
          <path
            fill="#432A97"
            opacity="1.000000"
            stroke="none"
            d="
M531.044617,118.610550 
	C528.657104,118.389809 526.067322,117.950035 523.529724,117.304970 
	C520.399048,116.509148 518.668579,117.241013 518.989197,120.835747 
	C519.235474,123.596443 519.171875,126.177696 515.666199,127.085258 
	C514.984436,127.261765 514.389709,128.404587 514.065979,129.224548 
	C512.719971,132.633392 511.682739,136.174423 510.184723,139.510941 
	C506.544342,147.619003 502.844055,155.705933 498.919708,163.679245 
	C494.179413,173.310471 489.283203,182.869476 484.204559,192.325500 
	C483.296783,194.015762 481.174927,195.053970 480.469177,195.659363 
	C479.838165,198.869553 479.355286,201.326096 478.872406,203.782639 
	C478.554321,203.706619 478.236206,203.630615 477.918091,203.554596 
	C478.270813,205.799744 478.623505,208.044891 478.955078,210.691879 
	C475.839508,209.121567 472.745087,207.149429 469.151978,204.690399 
	C468.434692,201.134659 468.216125,198.065811 468.379486,194.988525 
	C469.815582,193.520676 471.009949,192.140045 471.885986,190.580582 
	C472.836761,188.888123 473.425232,186.996765 474.286011,185.248413 
	C478.863403,175.951141 483.551514,166.707550 488.041168,157.368378 
	C489.882233,153.538681 491.321655,149.515900 493.058044,145.303680 
	C493.953522,145.120377 495.216034,144.861938 496.053070,144.690582 
	C496.581085,141.961090 496.518890,139.305588 497.607697,137.263916 
	C499.990021,132.796768 503.031616,128.685745 505.620941,124.320694 
	C506.139587,123.446388 505.844360,122.089287 505.924377,120.954788 
	C510.076935,118.359413 514.162598,115.644157 518.433228,113.260338 
	C519.395508,112.723213 521.180115,112.972511 522.279236,113.499634 
	C525.230103,114.914848 528.014771,116.676437 531.044617,118.610550 
z"
          />
          <path
            fill="#030303"
            opacity="1.000000"
            stroke="none"
            d="
M526.888306,265.533508 
	C527.109924,268.403870 527.109924,270.883881 527.109924,272.819794 
	C523.776794,273.925293 519.957642,274.133698 518.047729,276.150299 
	C516.433228,277.854950 517.030945,281.654724 516.599426,284.904907 
	C520.201294,284.904907 523.146240,284.904907 526.411377,284.904907 
	C526.411377,287.617645 526.411377,290.014099 526.411377,292.761841 
	C523.490784,292.761841 520.555115,292.761841 517.204468,292.761841 
	C517.204468,306.151794 517.204468,319.215027 517.204468,332.641113 
	C512.743042,332.641113 508.685181,332.641113 504.215515,332.641113 
	C504.215515,319.786438 504.215515,306.719055 504.215515,293.301514 
	C502.325928,293.110565 500.788025,292.955139 499.146729,292.789276 
	C499.146729,290.252197 499.146729,287.961823 499.146729,285.326111 
	C500.610291,285.142120 502.148438,284.948761 504.136169,284.698883 
	C504.136169,281.943298 503.688141,279.222748 504.214355,276.705872 
	C506.402130,266.241638 512.946838,264.594574 523.232727,264.579254 
	C524.377075,264.577545 525.521973,264.946228 526.888306,265.533508 
z"
          />
          <path
            fill="#2F4DEA"
            opacity="1.000000"
            stroke="none"
            d="
M559.190063,236.976746 
	C559.190063,236.976746 558.989990,236.606384 558.986938,236.013519 
	C557.961670,233.967178 556.939514,232.513687 555.917358,231.060196 
	C555.917358,231.060196 555.965820,231.000504 555.900391,230.812515 
	C555.689514,230.331161 555.469238,230.113434 555.174194,229.971344 
	C555.174194,229.971344 554.961426,229.631058 555.016724,229.064850 
	C556.800232,227.480911 558.528503,226.463165 559.488831,225.897629 
	C562.395630,228.348648 564.576233,230.187286 566.864014,232.222290 
	C566.971313,232.418640 567.041260,232.860626 567.101807,233.048645 
	C567.441589,233.149445 567.720764,233.062241 568.106018,232.762024 
	C568.416992,232.295898 568.671753,232.111633 569.231567,231.824554 
	C569.611633,231.269547 569.736450,230.886154 569.849243,230.360657 
	C569.837341,230.218536 570.055542,230.034821 570.279419,229.893707 
	C570.651855,229.439941 570.800476,229.127274 570.970764,228.603485 
	C570.992432,228.392349 571.179199,228.011108 571.371582,227.878235 
	C571.794617,227.514709 571.924316,227.236938 571.966919,226.947678 
	C571.980774,226.983322 571.905701,226.968704 572.173462,226.828430 
	C572.630005,226.132904 572.818787,225.577667 573.001526,225.012527 
	C572.995483,225.002640 573.018005,225.000778 573.247925,224.871429 
	C573.605347,224.423431 573.732910,224.104782 573.873230,223.850540 
	C573.886047,223.914963 573.756042,223.896118 574.063232,223.775543 
	C574.565002,223.052017 574.759705,222.449066 574.980957,221.626007 
	C575.007446,221.405884 575.202759,221.007812 575.390137,220.879898 
	C575.804138,220.531799 575.934692,220.264618 575.984741,219.974487 
	C576.000305,219.998520 575.947083,219.977264 576.207520,219.833740 
	C576.652954,219.141464 576.838013,218.592697 577.007812,218.027893 
	C576.992615,218.011841 577.036804,218.010269 577.268188,217.860336 
	C577.636780,217.376480 577.773987,217.042557 577.911560,216.526367 
	C577.911926,216.344086 578.110962,216.038666 578.317566,215.909424 
	C578.775696,215.550278 578.920227,215.266006 578.974976,214.959412 
	C578.992249,214.991486 578.922791,214.969620 579.188171,214.821365 
	C579.629395,214.109985 579.805237,213.546875 579.990601,212.991882 
	C580.000000,213.000000 579.980408,212.985275 580.218872,212.863785 
	C580.592896,212.422058 580.728333,212.101822 580.858887,211.835403 
	C580.854004,211.889221 580.763123,211.830688 581.140259,211.841156 
	C582.345032,209.901062 583.172668,207.950485 584.000122,205.999954 
	C584.000000,206.000000 584.000000,206.001053 584.241089,205.853729 
	C584.621033,205.364365 584.759827,205.022293 584.899536,204.496368 
	C584.900391,204.312500 585.117249,204.015533 585.322815,203.884216 
	C585.776428,203.517593 585.914429,203.229523 585.950684,202.932678 
	C585.958923,202.976654 585.883850,202.927902 586.250732,202.897202 
	C587.087830,201.593948 587.558105,200.321396 588.010681,199.031799 
	C587.992981,199.014755 588.041992,199.014175 588.266052,198.884857 
	C588.626038,198.446518 588.761963,198.137527 588.908325,197.882111 
	C588.918762,197.935699 588.810120,197.924301 589.100037,197.798248 
	C589.577026,197.094360 589.764160,196.516525 589.972473,195.970322 
	C589.993713,196.001968 589.932495,195.956253 590.306580,195.911942 
	C591.081970,194.796066 591.483276,193.724503 591.921143,192.309052 
	C594.298340,187.353958 596.638855,182.742722 598.734009,177.811890 
	C593.094299,176.781021 587.700012,176.069717 582.305725,175.358429 
	C582.059265,174.908691 581.812866,174.458969 581.566406,174.009232 
	C583.217346,172.710403 584.856445,170.312500 586.521057,170.294693 
	C595.885071,170.194595 605.255188,170.667236 614.623291,170.944534 
	C599.676758,196.870071 584.730225,222.795593 569.141113,248.852646 
	C567.613770,248.679276 566.729126,248.374405 565.844421,248.069519 
	C565.844421,248.069519 565.932068,248.050735 565.863586,247.843750 
	C565.541626,247.410095 565.288208,247.183411 565.034729,246.956726 
	C565.034729,246.956726 564.830261,246.804733 564.816040,246.360809 
	C564.513428,245.617615 564.225159,245.318359 563.936890,245.019104 
	C563.629639,244.587540 563.322388,244.155991 562.922241,243.195953 
	C562.580688,242.446442 562.332153,242.225418 562.083618,242.004395 
	C562.083618,242.004395 561.989441,241.937027 562.011597,241.523743 
	C561.336304,240.090225 560.638916,239.070007 559.941467,238.049774 
	C559.941467,238.049774 559.984619,237.993881 559.912964,237.802460 
	C559.698120,237.323746 559.481018,237.112320 559.190063,236.976746 
z"
          />
          <path
            fill="#050505"
            opacity="1.000000"
            stroke="none"
            d="
M776.009521,296.179810 
	C776.009216,303.158783 776.005981,309.652313 776.009766,316.145874 
	C776.014587,324.487793 776.363159,324.859344 784.777832,325.244904 
	C784.777832,327.939178 784.777832,330.649567 784.777832,333.869385 
	C780.429199,333.869385 776.258118,334.315033 772.224548,333.760651 
	C766.741394,333.007019 764.089600,329.975403 763.953918,324.351196 
	C763.705872,314.070251 763.883362,303.779053 763.883362,293.254181 
	C761.079651,293.103821 758.959045,292.990082 756.480774,292.857147 
	C756.480774,290.468323 756.480774,288.073395 756.480774,285.219330 
	C758.519836,285.092896 760.767700,284.953552 763.436035,284.788147 
	C763.436035,280.924896 763.436035,277.177917 763.436035,273.219269 
	C767.778564,273.219269 771.681213,273.219269 775.981079,273.219269 
	C775.981079,277.086212 775.981079,280.838928 775.981079,284.961487 
	C779.440735,284.961487 782.529114,284.961487 785.803467,284.961487 
	C785.803467,287.755676 785.803467,290.163239 785.803467,293.010376 
	C783.397400,293.010376 781.062866,292.730316 778.851807,293.131287 
	C777.784607,293.324860 776.949768,294.799683 776.009521,296.179810 
z"
          />
          <path
            fill="#46268E"
            opacity="1.000000"
            stroke="none"
            d="
M505.587280,121.032944 
	C505.844360,122.089287 506.139587,123.446388 505.620941,124.320694 
	C503.031616,128.685745 499.990021,132.796768 497.607697,137.263916 
	C496.518890,139.305588 496.581085,141.961090 496.053070,144.690582 
	C495.216034,144.861938 493.953522,145.120377 493.058044,145.303680 
	C491.321655,149.515900 489.882233,153.538681 488.041168,157.368378 
	C483.551514,166.707550 478.863403,175.951141 474.286011,185.248413 
	C473.425232,186.996765 472.836761,188.888123 471.885986,190.580582 
	C471.009949,192.140045 469.815582,193.520676 468.377075,194.520294 
	C467.997192,187.041550 468.001648,180.022598 468.356110,172.966125 
	C475.781006,168.224915 475.422760,159.657944 478.939575,153.101196 
	C480.272888,150.615326 480.164093,146.482635 482.055420,145.357239 
	C485.627472,143.231750 486.130524,140.738037 486.062256,137.222137 
	C486.001617,134.097717 486.600830,131.210266 490.904266,133.766998 
	C491.304443,134.004730 492.082642,133.606232 492.957428,133.456604 
	C492.585358,131.766266 492.275024,130.356445 491.964722,128.946625 
	C491.964722,128.946625 491.991608,129.004257 492.278290,128.971069 
	C493.030731,128.587555 493.496460,128.237228 493.962219,127.886894 
	C493.962219,127.886894 493.974731,127.968895 494.260193,127.945816 
	C494.998413,127.537415 495.451172,127.152084 495.903961,126.766754 
	C495.903961,126.766754 495.922668,126.899078 496.110535,126.839561 
	C496.531647,126.538094 496.764923,126.296143 496.998199,126.054184 
	C499.748840,124.406487 502.499512,122.758789 505.587280,121.032944 
z"
          />
          <path
            fill="#373EC8"
            opacity="1.000000"
            stroke="none"
            d="
M523.271667,233.629074 
	C520.041199,232.481644 517.136902,231.253220 513.945190,229.903214 
	C521.382202,214.696625 528.510681,200.120972 535.832886,185.149094 
	C537.256775,185.841217 538.330017,186.362915 539.723389,186.921509 
	C540.043457,186.958389 540.053589,186.923691 540.035400,187.317627 
	C541.088379,189.086914 542.159607,190.462296 543.183716,191.865906 
	C543.136658,191.894150 543.126953,191.784744 543.174194,192.043274 
	C543.470032,192.536972 543.718628,192.772125 544.241455,193.220261 
	C546.293945,196.630798 548.072205,199.828369 549.501892,202.976212 
	C547.244446,202.083298 545.335571,201.240112 542.878784,200.154907 
	C541.628784,205.007324 536.483826,207.926331 537.757385,213.968140 
	C538.106445,215.623978 536.047058,217.968628 534.747009,219.743851 
	C533.219421,221.829620 531.315002,223.639389 529.574036,225.568863 
	C529.879272,225.832870 530.184509,226.096878 530.489746,226.360870 
	C533.379639,225.642151 536.269592,224.923447 539.159485,224.204712 
	C533.972290,227.319168 528.785034,230.433624 523.271667,233.629074 
z"
          />
          <path
            fill="#353ECD"
            opacity="1.000000"
            stroke="none"
            d="
M563.011108,176.977661 
	C557.282104,176.702133 551.553162,176.426605 544.087402,176.067551 
	C549.403198,164.093094 554.278503,153.110809 559.695129,140.909302 
	C561.369019,140.909302 564.357544,140.909302 567.736816,140.932022 
	C568.896240,141.269043 569.665039,141.583344 570.918335,142.249420 
	C571.957336,142.720322 572.511780,142.839432 573.066284,142.958542 
	C573.066284,142.958542 573.008911,143.001892 573.001648,143.438751 
	C572.684692,148.908890 572.374939,153.942154 571.706299,159.017029 
	C570.281494,160.338074 568.922241,161.476013 568.220337,162.931076 
	C567.237793,164.967911 566.812256,167.265747 566.025391,169.406708 
	C565.088623,171.955353 564.021667,174.456131 563.011108,176.977661 
z"
          />
          <path
            fill="#000000"
            opacity="1.000000"
            stroke="none"
            d="
M488.842102,284.923462 
	C489.882751,285.705566 490.923584,286.481628 490.928589,287.264313 
	C491.024994,302.342438 490.999969,317.421295 490.999969,332.749207 
	C486.834869,332.749207 482.958771,332.749207 478.919189,332.749207 
	C478.919189,316.830627 478.919189,301.128113 478.919189,284.920502 
	C482.142426,284.920502 485.251221,284.920502 488.842102,284.923462 
z"
          />
          <path
            fill="#482384"
            opacity="1.000000"
            stroke="none"
            d="
M491.622223,129.028503 
	C492.275024,130.356445 492.585358,131.766266 492.957428,133.456604 
	C492.082642,133.606232 491.304443,134.004730 490.904266,133.766998 
	C486.600830,131.210266 486.001617,134.097717 486.062256,137.222137 
	C486.130524,140.738037 485.627472,143.231750 482.055420,145.357239 
	C480.164093,146.482635 480.272888,150.615326 478.939575,153.101196 
	C475.422760,159.657944 475.781006,168.224915 468.352844,172.497040 
	C468.612640,161.966034 469.225708,151.866562 469.838806,141.767075 
	C469.838806,141.767075 469.886200,141.877457 470.103027,141.813324 
	C470.564026,141.470810 470.808197,141.192398 471.052368,140.913986 
	C477.794830,136.979446 484.537262,133.044907 491.622223,129.028503 
z"
          />
          <path
            fill="#3443D3"
            opacity="1.000000"
            stroke="none"
            d="
M539.138672,224.187195 
	C536.269592,224.923447 533.379639,225.642151 530.489746,226.360870 
	C530.184509,226.096878 529.879272,225.832870 529.574036,225.568863 
	C531.315002,223.639389 533.219421,221.829620 534.747009,219.743851 
	C536.047058,217.968628 538.106445,215.623978 537.757385,213.968140 
	C536.483826,207.926331 541.628784,205.007324 542.878784,200.154907 
	C545.335571,201.240112 547.244446,202.083298 549.598389,203.154907 
	C550.043518,203.383331 550.064148,203.789612 550.117065,204.032806 
	C550.299377,204.599625 550.519714,204.839737 550.907104,205.212753 
	C550.983093,205.429138 551.040222,205.884277 551.030701,206.251801 
	C551.706360,207.390778 552.391541,208.162247 553.055542,208.970856 
	C553.034302,209.008011 553.052979,208.925369 553.121643,209.153397 
	C553.344666,209.676163 553.576050,209.886078 554.077637,210.271332 
	C554.871094,211.656876 555.471252,212.782288 555.871643,214.193695 
	C553.527161,215.983856 551.003418,217.234009 552.663879,220.702316 
	C552.866333,221.125076 551.658020,222.223251 551.103516,223.008408 
	C550.306396,221.772400 549.509277,220.536377 548.096985,219.179657 
	C547.013428,219.441727 546.545044,219.824509 546.076660,220.207291 
	C546.076660,220.207291 546.066956,220.087402 545.883911,220.137283 
	C545.380127,220.309845 545.146606,220.526474 545.000244,220.837082 
	C545.000244,220.837082 544.577087,220.991806 544.107300,221.106232 
	C543.435425,221.495895 543.233337,221.771103 543.031189,222.046326 
	C543.031189,222.046326 543.002441,221.998657 542.632812,222.006378 
	C541.220825,222.745514 540.178528,223.476944 539.136230,224.208374 
	C539.136230,224.208374 539.117859,224.169678 539.138672,224.187195 
z"
          />
          <path
            fill="#3348D9"
            opacity="1.000000"
            stroke="none"
            d="
M563.477417,176.982086 
	C564.021667,174.456131 565.088623,171.955353 566.025391,169.406708 
	C566.812256,167.265747 567.237793,164.967911 568.220337,162.931076 
	C568.922241,161.476013 570.281494,160.338074 571.701416,159.423492 
	C572.725769,162.834457 573.396240,165.880554 574.066711,168.926651 
	C586.476624,168.956238 598.886963,168.947006 611.295837,169.075119 
	C612.543457,169.087997 613.782532,169.932343 614.824402,170.667664 
	C605.255188,170.667236 595.885071,170.194595 586.521057,170.294693 
	C584.856445,170.312500 583.217346,172.710403 581.566406,174.009232 
	C581.812866,174.458969 582.059265,174.908691 582.305725,175.358429 
	C587.700012,176.069717 593.094299,176.781021 598.273315,177.890366 
	C592.027161,178.203323 585.996216,178.118225 579.965271,178.033127 
	C574.624756,177.684265 569.284180,177.335388 563.477417,176.982086 
z"
          />
          <path
            fill="#060606"
            opacity="1.000000"
            stroke="none"
            d="
M483.340729,266.948181 
	C487.390686,266.537659 490.770233,267.354980 491.350281,271.343994 
	C491.670776,273.548035 490.846527,277.126129 489.294281,278.098175 
	C487.219238,279.397583 483.653473,279.553223 481.272736,278.692688 
	C479.686890,278.119507 477.908356,274.671722 478.276306,272.951050 
	C478.751312,270.729492 481.282654,268.947601 483.340729,266.948181 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M551.076416,223.255203 
	C551.658020,222.223251 552.866333,221.125076 552.663879,220.702316 
	C551.003418,217.234009 553.527161,215.983856 556.174194,214.366638 
	C560.036560,220.177704 563.396667,226.101807 566.756775,232.025925 
	C564.576233,230.187286 562.395630,228.348648 559.488831,225.897629 
	C558.528503,226.463165 556.800232,227.480911 555.001099,228.864777 
	C554.304443,228.379761 553.678650,227.528610 553.242798,226.172806 
	C552.891602,225.135376 552.350464,224.602585 551.809326,224.069794 
	C551.437988,224.038513 551.184631,223.849258 551.076416,223.255203 
z"
          />
          <path
            fill="#422973"
            opacity="1.000000"
            stroke="none"
            d="
M469.437317,141.840958 
	C469.225708,151.866562 468.612640,161.966034 468.002808,172.534592 
	C468.001648,180.022598 467.997192,187.041550 467.995117,194.528717 
	C468.216125,198.065811 468.434692,201.134659 468.813293,204.574066 
	C468.317261,204.145889 467.093048,203.350479 467.088348,202.547913 
	C466.976196,183.489014 466.959534,164.429153 467.077728,145.370392 
	C467.084900,144.214523 468.353943,143.066498 469.437317,141.840958 
z"
          />
          <path
            fill="#402EA2"
            opacity="1.000000"
            stroke="none"
            d="
M574.033447,168.465485 
	C573.396240,165.880554 572.725769,162.834457 572.060303,159.381897 
	C572.374939,153.942154 572.684692,148.908890 573.017090,143.406311 
	C573.355225,146.793488 573.810608,150.644943 573.949524,154.507767 
	C574.111084,159.001434 573.995483,163.505035 574.033447,168.465485 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M555.967773,231.397171 
	C556.939514,232.513687 557.961670,233.967178 558.964722,235.804230 
	C557.969788,234.703232 556.993958,233.218689 555.967773,231.397171 
z"
          />
          <path
            fill="#432A97"
            opacity="1.000000"
            stroke="none"
            d="
M487.831848,216.403503 
	C489.382294,216.868622 491.126587,217.624420 492.901520,218.754105 
	C491.296692,218.316727 489.661194,217.505447 487.831848,216.403503 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M565.877319,248.393997 
	C566.729126,248.374405 567.613770,248.679276 568.849121,249.021530 
	C568.778259,249.870087 568.356750,250.681290 567.698486,251.948013 
	C566.932373,250.564453 566.421265,249.641464 565.877319,248.393997 
z"
          />
          <path
            fill="#373EC8"
            opacity="1.000000"
            stroke="none"
            d="
M539.468567,224.161835 
	C540.178528,223.476944 541.220825,222.745514 542.651367,222.027802 
	C541.960083,222.732773 540.880493,223.424026 539.468567,224.161835 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M559.985718,238.365143 
	C560.638916,239.070007 561.336304,240.090225 562.045166,241.476578 
	C561.380981,240.788620 560.705444,239.734558 559.985718,238.365143 
z"
          />
          <path
            fill="#3D33B0"
            opacity="1.000000"
            stroke="none"
            d="
M501.928894,224.491180 
	C502.802765,224.588638 503.838257,224.960129 504.911560,225.697739 
	C503.996399,225.630981 503.043488,225.198074 501.928894,224.491180 
z"
          />
          <path
            fill="#432A97"
            opacity="1.000000"
            stroke="none"
            d="
M480.949127,212.444351 
	C481.824615,212.556244 482.849396,212.950089 483.913269,213.708496 
	C483.001068,213.624146 482.049744,213.175232 480.949127,212.444351 
z"
          />
          <path
            fill="#3443D3"
            opacity="1.000000"
            stroke="none"
            d="
M551.884277,224.359711 
	C552.350464,224.602585 552.891602,225.135376 553.195007,225.851898 
	C552.624573,225.573639 552.291870,225.111633 551.884277,224.359711 
z"
          />
          <path
            fill="#432A97"
            opacity="1.000000"
            stroke="none"
            d="
M479.033508,211.354462 
	C479.496918,211.259888 480.082245,211.453735 480.785767,211.948273 
	C480.321106,212.046951 479.738251,211.844925 479.033508,211.354462 
z"
          />
          <path
            fill="#482384"
            opacity="1.000000"
            stroke="none"
            d="
M493.658325,127.931351 
	C493.496460,128.237228 493.030731,128.587555 492.253967,128.950439 
	C492.413452,128.633926 492.883942,128.304871 493.658325,127.931351 
z"
          />
          <path
            fill="#402EA2"
            opacity="1.000000"
            stroke="none"
            d="
M572.945679,142.667511 
	C572.511780,142.839432 571.957336,142.720322 571.220886,142.374878 
	C571.634338,142.224533 572.229736,142.300522 572.945679,142.667511 
z"
          />
          <path
            fill="#482384"
            opacity="1.000000"
            stroke="none"
            d="
M495.599945,126.826584 
	C495.451172,127.152084 494.998413,127.537415 494.222961,127.927505 
	C494.365509,127.583641 494.830719,127.235023 495.599945,126.826584 
z"
          />
          <path
            fill="#3D33B0"
            opacity="1.000000"
            stroke="none"
            d="
M505.072540,226.334381 
	C505.517975,226.228470 506.093872,226.402832 506.813446,226.854187 
	C506.372406,226.959015 505.787720,226.786835 505.072540,226.334381 
z"
          />
          <path
            fill="#373EC8"
            opacity="1.000000"
            stroke="none"
            d="
M546.393494,220.164886 
	C546.545044,219.824509 547.013428,219.441727 547.808228,219.051956 
	C547.659851,219.404129 547.185120,219.763306 546.393494,220.164886 
z"
          />
          <path
            fill="#482384"
            opacity="1.000000"
            stroke="none"
            d="
M496.723999,126.055893 
	C496.764923,126.296143 496.531647,126.538094 496.044525,126.829086 
	C496.010376,126.604607 496.230072,126.331093 496.723999,126.055893 
z"
          />
          <path
            fill="#422973"
            opacity="1.000000"
            stroke="none"
            d="
M470.768829,140.972290 
	C470.808197,141.192398 470.564026,141.470810 470.042969,141.811859 
	C470.005829,141.593201 470.245575,141.311905 470.768829,140.972290 
z"
          />
          <path
            fill="#3443D3"
            opacity="1.000000"
            stroke="none"
            d="
M555.159790,230.232803 
	C555.469238,230.113434 555.689514,230.331161 555.899902,230.850952 
	C555.691650,230.883011 555.418518,230.688629 555.159790,230.232803 
z"
          />
          <path
            fill="#432A97"
            opacity="1.000000"
            stroke="none"
            d="
M492.963898,219.307846 
	C493.165710,219.199265 493.459167,219.345993 493.882263,219.706818 
	C493.693085,219.801651 493.374329,219.682404 492.963898,219.307846 
z"
          />
          <path
            fill="#373EC8"
            opacity="1.000000"
            stroke="none"
            d="
M545.267822,220.873856 
	C545.146606,220.526474 545.380127,220.309845 545.942749,220.149673 
	C545.968323,220.378326 545.751892,220.644485 545.267822,220.873856 
z"
          />
          <path
            fill="#373EC8"
            opacity="1.000000"
            stroke="none"
            d="
M543.292969,221.984695 
	C543.233337,221.771103 543.435425,221.495895 543.883545,221.132156 
	C543.937927,221.336792 543.746338,221.629929 543.292969,221.984695 
z"
          />
          <path
            fill="#3D33B0"
            opacity="1.000000"
            stroke="none"
            d="
M506.967224,227.318634 
	C507.226471,227.101151 507.517609,227.249222 507.880402,227.721207 
	C507.690063,227.815369 507.372406,227.695053 506.967224,227.318634 
z"
          />
          <path
            fill="#432A97"
            opacity="1.000000"
            stroke="none"
            d="
M484.011292,214.277435 
	C484.233826,214.083832 484.501099,214.214325 484.854309,214.619034 
	C484.688599,214.707687 484.393860,214.610458 484.011292,214.277435 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M559.171753,237.232086 
	C559.481018,237.112320 559.698120,237.323746 559.899292,237.834091 
	C559.689331,237.867233 559.421326,237.677338 559.171753,237.232086 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M562.083008,242.277283 
	C562.332153,242.225418 562.580688,242.446442 562.891418,242.915680 
	C562.663208,242.959320 562.372864,242.754745 562.083008,242.277283 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M563.997314,245.330963 
	C564.225159,245.318359 564.513428,245.617615 564.848022,246.237518 
	C564.615479,246.253067 564.336609,245.947952 563.997314,245.330963 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M565.058105,247.231140 
	C565.288208,247.183411 565.541626,247.410095 565.859985,247.888428 
	C565.643677,247.928558 565.362549,247.717056 565.058105,247.231140 
z"
          />
          <path
            fill="#432A97"
            opacity="1.000000"
            stroke="none"
            d="
M485.959167,215.301392 
	C486.046814,215.037766 486.237488,215.022995 486.714966,215.000336 
	C487.001801,214.992462 487.007538,215.446884 487.008301,215.673401 
	C486.693420,215.783356 486.377808,215.666794 485.959167,215.301392 
z"
          />
          <path
            fill="#FDFDFD"
            opacity="1.000000"
            stroke="none"
            d="
M673.918945,280.907684 
	C683.205322,282.378082 686.937195,288.945496 687.583008,296.570648 
	C688.074890,302.378052 686.756165,308.702942 684.751465,314.259521 
	C682.563416,320.324188 676.839966,322.778503 670.441833,322.975891 
	C666.478943,323.098114 662.509277,322.998566 658.289001,322.998566 
	C658.289001,308.636566 658.289001,294.579437 658.289001,279.685272 
	C663.424438,280.076874 668.461670,280.461029 673.918945,280.907684 
z"
          />
          <path
            fill="#FCFCFC"
            opacity="1.000000"
            stroke="none"
            d="
M543.085083,308.153687 
	C544.021973,304.412903 544.406921,300.796875 546.019470,297.853333 
	C548.417358,293.476196 552.637207,291.363922 557.744629,292.186554 
	C562.977844,293.029449 567.107056,297.133301 567.712830,302.978760 
	C568.164551,307.337982 567.889221,311.959381 566.924866,316.235565 
	C565.408630,322.958893 561.685547,325.478424 555.302490,325.356873 
	C549.948303,325.254913 545.542236,321.667633 544.116089,315.944916 
	C543.519897,313.552429 543.412231,311.038177 543.085083,308.153687 
z"
          />
          <path
            fill="#FDFDFD"
            opacity="1.000000"
            stroke="none"
            d="
M454.004028,304.377686 
	C454.006622,307.816101 454.120514,310.807709 453.973694,313.786499 
	C453.685913,319.625366 449.830872,324.265900 444.806641,325.035278 
	C437.789429,326.109833 432.830048,323.932159 431.286957,317.424500 
	C430.081909,312.342590 430.046722,306.625183 431.014893,301.476624 
	C432.227203,295.029846 437.552582,291.575195 443.006439,292.042816 
	C449.084747,292.563995 453.109283,296.914856 454.004028,304.377686 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M277.955383,283.430237 
	C278.023438,282.909607 278.006927,282.731750 278.065216,282.583313 
	C278.170715,282.314728 278.320831,282.063721 278.452332,281.805359 
	C279.584015,284.823395 280.825409,287.805817 281.820618,290.868195 
	C283.178528,295.046814 284.348938,299.286377 285.685028,303.787231 
	C280.971497,303.787231 276.436951,303.787231 271.299622,303.787231 
	C273.510010,297.093811 275.707275,290.440063 277.955383,283.430237 
z"
          />
          <path
            fill="#F9FBFE"
            opacity="1.000000"
            stroke="none"
            d="
M566.864014,232.222290 
	C563.396667,226.101807 560.036560,220.177704 556.373901,214.080658 
	C555.471252,212.782288 554.871094,211.656876 554.083374,210.007843 
	C553.614807,209.297928 553.333923,209.111649 553.052979,208.925369 
	C553.052979,208.925369 553.034302,209.008011 553.013184,208.649780 
	C552.341431,207.489120 551.690857,206.686691 551.040222,205.884277 
	C551.040222,205.884277 550.983093,205.429138 550.934326,204.940384 
	C550.611755,204.230957 550.337952,204.010284 550.064148,203.789612 
	C550.064148,203.789612 550.043518,203.383331 549.947021,203.204636 
	C548.072205,199.828369 546.293945,196.630798 544.220032,192.946381 
	C543.658569,192.234589 543.392761,192.009674 543.126953,191.784760 
	C543.126953,191.784744 543.136658,191.894150 543.126343,191.530762 
	C542.095215,189.752808 541.074402,188.338242 540.053589,186.923676 
	C540.053589,186.923691 540.043457,186.958389 540.058838,186.945221 
	C538.895813,184.269653 537.717468,181.607254 536.220825,178.225769 
	C550.694763,178.225769 564.873657,178.225769 579.508911,178.129456 
	C585.996216,178.118225 592.027161,178.203323 598.518799,178.209961 
	C596.638855,182.742722 594.298340,187.353958 591.636658,192.488144 
	C590.854492,193.992813 590.393494,194.974533 589.932495,195.956253 
	C589.932495,195.956253 589.993713,196.001968 589.696106,196.104248 
	C589.202332,196.779099 589.006226,197.351700 588.810120,197.924301 
	C588.810120,197.924301 588.918762,197.935699 588.655579,197.982330 
	C588.275574,198.357376 588.158813,198.685776 588.041992,199.014175 
	C588.041992,199.014175 587.992981,199.014755 587.756104,199.233215 
	C586.974060,200.610413 586.428955,201.769165 585.883850,202.927902 
	C585.883850,202.927902 585.958923,202.976654 585.698914,203.020355 
	C585.331726,203.381210 585.224487,203.698380 585.117249,204.015533 
	C585.117249,204.015533 584.900391,204.312500 584.647766,204.633118 
	C584.263428,205.302826 584.131714,205.651932 584.000000,206.001053 
	C584.000000,206.001053 584.000000,206.000000 583.722900,206.194031 
	C582.551514,208.202286 581.657349,210.016479 580.763123,211.830688 
	C580.763123,211.830688 580.854004,211.889221 580.604126,211.935791 
	C580.229614,212.316666 580.105042,212.650970 579.980408,212.985275 
	C579.980408,212.985275 580.000000,213.000000 579.724731,213.133575 
	C579.273865,213.834641 579.098328,214.402130 578.922791,214.969620 
	C578.922791,214.969620 578.992249,214.991486 578.718872,215.042664 
	C578.333984,215.408768 578.222473,215.723724 578.110962,216.038666 
	C578.110962,216.038666 577.911926,216.344086 577.659424,216.658661 
	C577.283569,217.318924 577.160156,217.664597 577.036804,218.010254 
	C577.036804,218.010269 576.992615,218.011841 576.778259,218.201996 
	C576.358276,218.920517 576.152649,219.448883 575.947021,219.977264 
	C575.947083,219.977264 576.000305,219.998520 575.748291,220.059814 
	C575.398438,220.416672 575.300598,220.712234 575.202759,221.007812 
	C575.202759,221.007812 575.007446,221.405884 574.700012,221.773102 
	C574.180420,222.725571 573.968201,223.310852 573.756042,223.896118 
	C573.756042,223.896118 573.886047,223.914963 573.621033,223.955017 
	C573.243347,224.330292 573.130676,224.665527 573.017944,225.000763 
	C573.018005,225.000778 572.995483,225.002640 572.761963,225.179443 
	C572.320862,225.893738 572.113281,226.431229 571.905762,226.968719 
	C571.905701,226.968704 571.980774,226.983322 571.725159,227.039215 
	C571.372742,227.400452 571.276001,227.705780 571.179199,228.011108 
	C571.179199,228.011108 570.992432,228.392349 570.718933,228.719742 
	C570.315491,229.376373 570.185547,229.705597 570.055542,230.034821 
	C570.055542,230.034821 569.837341,230.218536 569.597229,230.535767 
	C569.230225,231.234085 569.103271,231.615143 568.976318,231.996201 
	C568.671753,232.111633 568.416992,232.295898 567.864624,232.747803 
	C567.517151,232.946579 567.041260,232.860626 567.041260,232.860626 
	C567.041260,232.860626 566.971313,232.418640 566.864014,232.222290 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M812.828857,311.517761 
	C815.671753,311.209412 818.119202,311.067535 820.521851,310.928284 
	C821.582275,319.599457 819.163147,324.246918 813.397766,325.120392 
	C808.315430,325.890350 804.726257,323.990662 804.038025,320.166443 
	C803.317200,316.160980 806.496155,312.949127 812.828857,311.517761 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M614.032043,324.617004 
	C612.060913,324.989777 610.178040,325.665344 608.823181,325.092316 
	C606.673035,324.182892 603.966003,322.839661 603.137207,320.956848 
	C601.810059,317.942078 602.736023,314.418549 606.308289,313.172638 
	C610.129639,311.839844 614.212219,311.255920 618.169189,310.347778 
	C620.021362,317.862244 619.085449,320.981110 614.032043,324.617004 
z"
          />
          <path
            fill="#FBFBFB"
            opacity="1.000000"
            stroke="none"
            d="
M734.510803,322.503632 
	C732.651672,323.573761 731.126648,324.712311 729.445618,325.032990 
	C724.378296,325.999756 720.750427,324.008148 720.016052,320.210083 
	C719.343750,316.732910 722.302002,313.033722 726.913208,312.013794 
	C729.961426,311.339539 733.103943,311.091431 737.900940,310.412354 
	C736.682068,315.005920 735.727783,318.602020 734.510803,322.503632 
z"
          />
          <path
            fill="#FCFCFC"
            opacity="1.000000"
            stroke="none"
            d="
M390.262634,324.947266 
	C379.302551,326.075409 372.749939,319.044586 374.465668,307.630920 
	C375.114960,303.311462 376.752991,298.623383 379.398071,295.244476 
	C383.205048,290.381226 390.436432,291.082184 394.653107,295.628479 
	C400.631073,302.073730 400.692261,320.865448 390.262634,324.947266 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M581.140259,211.841156 
	C581.657349,210.016479 582.551514,208.202286 583.723022,206.194000 
	C583.172668,207.950485 582.345032,209.901062 581.140259,211.841156 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M586.250732,202.897202 
	C586.428955,201.769165 586.974060,200.610413 587.773804,199.250259 
	C587.558105,200.321396 587.087830,201.593948 586.250732,202.897202 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M590.306580,195.911942 
	C590.393494,194.974533 590.854492,193.992813 591.600098,192.832031 
	C591.483276,193.724503 591.081970,194.796066 590.306580,195.911942 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M576.207458,219.833740 
	C576.152649,219.448883 576.358276,218.920517 576.793457,218.218048 
	C576.838013,218.592697 576.652954,219.141464 576.207458,219.833740 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M579.188171,214.821365 
	C579.098328,214.402130 579.273865,213.834641 579.715271,213.125458 
	C579.805237,213.546875 579.629395,214.109985 579.188171,214.821365 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M572.173462,226.828430 
	C572.113281,226.431229 572.320862,225.893738 572.768005,225.189331 
	C572.818787,225.577667 572.630005,226.132904 572.173462,226.828430 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M574.063232,223.775543 
	C573.968201,223.310852 574.180420,222.725571 574.673462,221.993210 
	C574.759705,222.449066 574.565002,223.052017 574.063232,223.775543 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M589.100037,197.798248 
	C589.006226,197.351700 589.202332,196.779099 589.674866,196.072601 
	C589.764160,196.516525 589.577026,197.094360 589.100037,197.798248 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M584.241089,205.853729 
	C584.131714,205.651932 584.263428,205.302826 584.646851,204.816971 
	C584.759827,205.022293 584.621033,205.364365 584.241089,205.853729 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M585.322815,203.884232 
	C585.224487,203.698380 585.331726,203.381210 585.690674,202.976379 
	C585.914429,203.229523 585.776428,203.517593 585.322815,203.884232 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M588.266052,198.884857 
	C588.158813,198.685776 588.275574,198.357376 588.645142,197.928741 
	C588.761963,198.137527 588.626038,198.446518 588.266052,198.884857 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M580.218872,212.863785 
	C580.105042,212.650970 580.229614,212.316666 580.609009,211.881989 
	C580.728333,212.101822 580.592896,212.422058 580.218872,212.863785 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M577.268188,217.860321 
	C577.160156,217.664597 577.283569,217.318924 577.659058,216.840942 
	C577.773987,217.042557 577.636780,217.376480 577.268188,217.860321 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M575.390137,220.879883 
	C575.300598,220.712234 575.398438,220.416672 575.732727,220.035767 
	C575.934692,220.264618 575.804138,220.531799 575.390137,220.879883 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M573.247864,224.871429 
	C573.130676,224.665527 573.243347,224.330292 573.608215,223.890594 
	C573.732910,224.104782 573.605347,224.423431 573.247864,224.871429 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M571.371582,227.878235 
	C571.276001,227.705780 571.372742,227.400452 571.711304,227.003571 
	C571.924316,227.236938 571.794617,227.514709 571.371582,227.878235 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M570.279419,229.893707 
	C570.185547,229.705597 570.315491,229.376373 570.697266,228.930878 
	C570.800476,229.127274 570.651855,229.439941 570.279419,229.893707 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M569.231567,231.824554 
	C569.103271,231.615143 569.230225,231.234085 569.609131,230.677887 
	C569.736450,230.886154 569.611633,231.269547 569.231567,231.824554 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M578.317566,215.909424 
	C578.222473,215.723724 578.333984,215.408768 578.701660,215.010590 
	C578.920227,215.266006 578.775696,215.550278 578.317566,215.909424 
z"
          />
          <path
            fill="#374ED9"
            opacity="1.000000"
            stroke="none"
            d="
M567.101807,233.048645 
	C567.041260,232.860626 567.517151,232.946579 567.758545,232.960800 
	C567.720764,233.062241 567.441589,233.149445 567.101807,233.048645 
z"
          />
          <path
            fill="#3A39BD"
            opacity="1.000000"
            stroke="none"
            d="
M540.035400,187.317612 
	C541.074402,188.338242 542.095215,189.752808 543.173462,191.502518 
	C542.159607,190.462296 541.088379,189.086914 540.035400,187.317612 
z"
          />
          <path
            fill="#3A39BD"
            opacity="1.000000"
            stroke="none"
            d="
M543.174194,192.043289 
	C543.392761,192.009674 543.658569,192.234589 543.945801,192.733398 
	C543.718628,192.772125 543.470032,192.536972 543.174194,192.043289 
z"
          />
          <path
            fill="#373EC8"
            opacity="1.000000"
            stroke="none"
            d="
M551.030701,206.251801 
	C551.690857,206.686691 552.341431,207.489120 553.034363,208.612625 
	C552.391541,208.162247 551.706360,207.390778 551.030701,206.251801 
z"
          />
          <path
            fill="#373EC8"
            opacity="1.000000"
            stroke="none"
            d="
M550.117065,204.032806 
	C550.337952,204.010284 550.611755,204.230957 550.858276,204.723999 
	C550.519714,204.839737 550.299377,204.599625 550.117065,204.032806 
z"
          />
          <path
            fill="#373EC8"
            opacity="1.000000"
            stroke="none"
            d="
M553.121643,209.153397 
	C553.333923,209.111649 553.614807,209.297928 553.890076,209.747711 
	C553.576050,209.886078 553.344666,209.676163 553.121643,209.153397 
z"
          />
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
